import Vue from 'vue'
import App from './App.vue'
import VueParticles from 'vue-particles'
import router from './router'
import VueScrollReveal from 'vue-scroll-reveal';

Vue.use(VueScrollReveal, {
  class: 'v-scroll-reveal', // A CSS class applied to elements with the v-scroll-reveal directive; useful for animation overrides.
  duration: 1000,
  delay: 750,
  origin: 'right',
  distance: '10px',
  useDelay: 'always',
});
Vue.use(VueParticles)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
