<template>
  <div class="home">
    <div class="container">
      <about/>

      <funds-raised/>

      <partners/>

      <join/>
      <section class="bg-white">
        <h1>Token Burns</h1>
        <p>As $PUPPY hits market cap milestones, we will permanently burn $PUPPY tokens, further decreasing supply.</p>
        <div class="market-cap-burns-section rainbow">
          <p>🚀 $10M Market Cap ⁠— 1% total supply burn 🔥</p>
          <p>🚀 $100M Market Cap ⁠— 2% total supply burn 🔥</p>
          <p>🚀 $500M Market Cap ⁠— 5% total supply burn 🔥</p>
          <p>🚀 $1B Market Cap ⁠— 10% total supply burn 🔥</p>
        </div>
      </section>

      <section class="together bg-white">
        <div class="container">
          <p v-scroll-reveal>Together we will build #PuppyGang into one of the strongest mission-driven cryptocurrency communities, while saving the lives of 150,000 dogs per year.</p>
          <div>
            <ul class="list-style-none" v-scroll-reveal="{ delay: 1000 }">
              <li>
                <router-link to="/how-to-buy">How To Buy Puppy Coin →</router-link>
              </li>
              <li class="button pancake-swap">
                <a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb8d14376a2da9e500aa8fa2537351c0440e06c61">Buy on Pancakeswap →</a>
              </li>
              <li class="button">
                <a target="_blank" href="https://poocoin.app/tokens/0xb8d14376a2da9e500aa8fa2537351c0440e06c61">View Charts on PooCoin →</a>
              </li>
              <li class="button">
                <a target="_blank" href="#">Charity Wallet →</a>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section class="tokenomics bg-white">
        <div class="container">
          <h1>Tokenomics</h1>
          <p>Initial Supply: 10,000,000,000,000 capped.</p>
          <p>2.5% of every transaction is sent to a public wallet that is then donated to organizations that saves puppies.</p>
          <p>2.5% is used to automatically add liquidity.</p>
          <p>NO CAP - the more $PUPPY is held and traded, the more puppies we save!</p>
        </div>
      </section>

      <socials/>
    </div>
  </div>
</template>

<script>
import About from '@/components/About.vue'
import FundsRaised from '@/components/FundsRaised.vue'
import Partners from '@/components/Partners.vue'
import Join from '@/components/Join.vue'
import Socials from '@/components/Socials.vue'

export default {
  name: 'Home',
  components: {
    About,
    FundsRaised,
    Partners,
    Join,
    Socials,
  },
}
</script>

<style scoped>
section.together {
  padding-top: 0;
}
section.together p {
  text-align: left;
  font-size: 24px;
  margin-top: 0;
}
section.together ul {
  text-align: right;
  padding-right: 32px;
  margin: 32px auto 0;
}
section.together li {
  padding-top: 8px;
}
section.together a {
  font-size: 20px;
  padding-top: 12px;
  color: #fa9d20;
}
section.together a:hover {
  color: black;
}
section.tokenomics {
  padding-top: 0;
}
section.tokenomics h1 {
  margin-top: 0;
}
.market-cap-burns-section {
  margin-top: 56px;
  background: #160909;
  color: white;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid white;
}
.market-cap-burns-section p {
  font-size: 13px;
}
.rainbow {
	position: relative;
	z-index: 0;
	border-radius: 10px;
	overflow: hidden;
  color: black;
  padding: 12px 1rem;
}
.rainbow:before {
  content: '';
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #399953;
  background-repeat: no-repeat;
  background-size: 50% 50%, 50% 50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  background-image: linear-gradient(#f88a8d, #f88a8d), linear-gradient(#ff9b00, #ff9b00), linear-gradient(#ff2600, #ff2600), linear-gradient(#2ba8ea, #2ba8ea);
  animation: rotate 4s linear infinite;
}
.rainbow:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 6px;
  top: 6px;
  width: calc(100% - 12px);
  height: calc(100% - 12px);
  background: white;
  border-radius: 5px;
}

@media (max-width: 768px) {
  section.together ul {
    max-width: 335px;
  }
}

@media (min-width: 769px) {
  section.together .container {
    display: grid;
    grid-template-columns: auto max-content;
    max-width: 960px;
  }
  section.together ul {
    margin: 0;
  }
  section.together li:first-of-type,
  section.together li:first-of-type a {
    padding-top: 0;
  }
  section.together p {
    margin: 0;
  }
  .market-cap-burns-section p {
    font-size: 20px;
  }
  .rainbow {
    padding: 2rem;
  }
}

@keyframes rotate {
	100% { transform: rotate(1turn); }
}
</style>
