<template>
  <div :class="'container ' + (active ? 'active': '')">
    <ul>
      <li>
        <router-link to="/">
          <img @click="$emit('change-active-state')" class="puppy-logo" alt="Puppy Coin logo" src="../assets/logo.png">
        </router-link>
      </li>

      <transition name="slide-fade">
        <li v-if="showLink1">
          <router-link to="/">
            <span @click="$emit('change-active-state')">Home</span>
          </router-link>
        </li>
      </transition>

      <transition name="slide-fade">
        <li v-if="showLink2">
          <router-link to="/mission">
            <span @click="$emit('change-active-state')">Mission</span>
          </router-link>
        </li>
      </transition>

      <transition name="slide-fade">
        <li v-if="showLink3">
          <router-link to="/how-to-buy">
            <span @click="$emit('change-active-state')">How To Buy Puppy Coin</span>
          </router-link>
        </li>
      </transition>

      <transition name="slide-fade">
        <li class="button pancake-swap" v-if="showLink4">
          <a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb8d14376a2da9e500aa8fa2537351c0440e06c61">
            <img src="../assets/pancakeswap-cake-logo.svg"/>
            <span>Buy on Pancakeswap</span>
          </a>
        </li>
      </transition>

      <transition name="slide-fade">
        <li class="button" v-if="showLink5">
          <a target="_blank" href="https://poocoin.app/tokens/0xb8d14376a2da9e500aa8fa2537351c0440e06c61">
            <img src="../assets/poocoin.png"/>
            <span>View on PooCoin</span>
          </a>
        </li>
      </transition>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    active: Boolean,
    showLink1: Boolean,
    showLink2: Boolean,
    showLink3: Boolean,
    showLink4: Boolean,
    showLink5: Boolean,
  },
  mounted() {
    this.$emit('show-links')
  },
}
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.container {
  width: 0;
}
ul {
  opacity: 0;
  width: 100vw;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: #262626;
  background: #ffefef;
  color: white;
  z-index: 5;
  transform: translateY(100%);
  transition: opacity 200ms;
}
.active ul {
  opacity: 1;
  transform: translateY(0);
}
li {
  margin-bottom: 1.75rem;
  font-size: 24px;
  padding-left: 0;
}
li:first-child {
  margin-top: 108px;
}
li:last-child {
  margin-bottom: 0;
}
li.button {
  font-size: 18px;
}
li.button img {
  max-height: 40px;
  margin-right: 8px;
}
a {
  color: white;
  color: #b62525;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
a:hover {
  transition: color 350ms;
  color: #fb9b01;
}
img.puppy-logo {
  max-width: 128px;
}
</style>
