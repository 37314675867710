<template>
  <nav>
    <div class="background-blur"/>
    <Burger :active="active" @change-active-state="changeActiveState" />
    <nav-menu-mobile
      v-if="mobile && active"
      :active="active"
      :showLink1="showLink1"
      :showLink2="showLink2"
      :showLink3="showLink3"
      :showLink4="showLink4"
      :showLink5="showLink5"
      @change-active-state="changeActiveState"
      @show-links="showLinks"
    />
    <nav-menu-desktop v-else-if="!mobile" />
    <toggle-theme v-if="mobile"/>
  </nav>
</template>

<script>
import Burger from './Burger.vue';
import NavMenuMobile from './NavMenuMobile.vue'
import NavMenuDesktop from './NavMenuDesktop.vue'
import ToggleTheme from './ToggleTheme.vue'

export default {
  components: {
    Burger,
    NavMenuMobile,
    NavMenuDesktop,
    ToggleTheme
  },
  data() {
    return {
      mobile: false,
      active: false,
      showLink1: false,
      showLink2: false,
      showLink3: false,
      showLink4: false,
      showLink5: false,
    }
  },
  methods: {
    changeActiveState() {
      this.active = !this.active
      if (!this.active) {
        document.body.style.overflow = 'initial';
        this.showLink1 = false
        this.showLink2 = false
        this.showLink3 = false
        this.showLink4 = false
        this.showLink5 = false
      } else {
        document.body.style.overflow = 'hidden';
      }
    },
    showLinks() {
      setTimeout(() => {
        this.showLink1 = true
  
        setTimeout(() => {
          this.showLink2 = true
  
            setTimeout(() => {
              this.showLink3 = true
  
              setTimeout(() => {
                this.showLink4 = true

                setTimeout(() => {
                  this.showLink5 = true
                }, 350)
              }, 350)
            }, 350)
        }, 350)
      }, 350)
    }
  },
  created() {
    if (window.screen.width <= 768) {
      this.mobile = true
    }
  }
}
</script>

<style scoped>
nav {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #ffefef;
}
@media (min-width: 769px) {
  .background-blur {
    width: 100%;
    position: absolute;
    background: #feefee;
  }
}
</style>
