<template>
  <div class="container">
    <ul>
      <li>
        <router-link to="/">
          <img @click="$emit('change-active-state')" class="puppy-logo" alt="Puppy Coin logo" src="../assets/logo.png">
        </router-link>
      </li>

      <li>
        <router-link to="/">
          <span @click="$emit('change-active-state')">Home</span>
        </router-link>
      </li>

      <li>
        <router-link to="/mission">
          <span @click="$emit('change-active-state')">Mission</span>
        </router-link>
      </li>

      <li>
        <router-link to="/how-to-buy">
          <span @click="$emit('change-active-state')">How To Buy Puppy Coin</span>
        </router-link>
      </li>
      
      <li class="button pancake-swap">
        <a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb8d14376a2da9e500aa8fa2537351c0440e06c61">
          <img src="../assets/pancakeswap-cake-logo.svg"/>
          <span>Buy on Pancakeswap</span>
        </a>
      </li>

      <li class="button">
        <a target="_blank" href="https://poocoin.app/tokens/0xb8d14376a2da9e500aa8fa2537351c0440e06c61">
          <img src="../assets/poocoin.png"/>
          <span>View on PooCoin</span>
        </a>
      </li>

      <li>
        <toggle-theme/>
      </li>
    </ul>
  </div>
</template>

<script>
import ToggleTheme from './ToggleTheme.vue'
export default {
  name: 'NavMenuDesktop',
  components: {
    ToggleTheme
  }
}
</script>

<style scoped>
.container {
  width: 0;
}
ul {
  opacity: 0;
  width: 100%;
  height: 100vh;
  list-style: none;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background: #262626;
  background: #ffefef;
  color: white;
  z-index: 5;
  transform: translateY(100%);
  transition: opacity 200ms;
}
.active ul {
  opacity: 1;
  transform: translateY(0);
}
li {
  margin-bottom: 1.75rem;
  font-size: 24px;
  padding-left: 0;
}
li:first-child {
  margin-top: 108px;
}
li:last-child {
  margin-bottom: 0;
}
li.button {
  font-size: 18px;
}
li.button img {
  max-height: 40px;
  margin-right: 8px;
}
a {
  color: white;
  color: #b62525;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
a:hover {
  transition: color 350ms;
  color: #fb9b01;
}
img.puppy-logo {
  max-width: 123px;
}

@media (min-width: 769px) {
  nav {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    background: #feefee;
  }
  .container {
    width: 7rem;
    display: flex;
    width: 100%;
    margin: 0 auto;
  }
  ul {
    opacity: 1;
    top: initial;
    transform: translateY(0);
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
  }
  li {
    font-size: 1rem;
    padding: 0;
    margin: 8px 16px;
  }
  li:first-child {
    margin-top: 8px;
  }
  li:last-child {
    margin-bottom: 8px;
  }
  li.button {
    font-size: 14px;
    margin: 8px;
    font-weight: 500;
  }
  li.button a {
    padding: 4px 8px;
    border: 1px solid;
    overflow: hidden;
    position: relative;
  }
  li.button a:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
  }
  li.button a:hover:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
  li.button a span {
    z-index: 20;
  }
  li.button img {
    max-height: 30px;
    margin-right: 4px;
  }
  li.button.pancake-swap {
    margin-left: 88px;
  }
  li.button.pancake-swap span {
    display: block;
    width: 171px;
  }
}
</style>
