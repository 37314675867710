<template>
  <section class="partners bg-white">

    <h1 v-scroll-reveal>Launch Partners</h1>
    <div class="container">
        <a target="_blank" href="https://www.theanimalrescuemission.org/">
          <picture>
            <img v-scroll-reveal="{ delay: 1000, duration: 2000 }" src="@/assets/animal-rescue-mission-logo.jpg"/>
          </picture>
        </a>

        <a target="_blank" href="https://drgaryrichter.com/">
          <picture>
            <img v-scroll-reveal="{ delay: 1000, duration: 2000 }" src="@/assets/dr-gary-richter-logo.png"/>
          </picture>
        </a>
      
        <a target="_blank" href="http://www.ultimatepetnutrition.com/">
          <picture>
            <img v-scroll-reveal="{ delay: 1000, duration: 2000 }" src="@/assets/ult-pet-nutrition-logo.png"/>
          </picture>
        </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Partners',
}
</script>

<style scoped>
h1 {
  margin: 0 auto;
}
.container {
  max-width: 1100px;
  margin: auto;
}
picture {
  display: flex;
  align-items: center;
  justify-content: center;
}
img {
  max-height: 96px;
}

@media (max-width: 768px) {
  picture:first-of-type img {
    margin-top: 32px;
  }
  img {
    margin-top: 48px;
  }
}

@media (min-width: 769px) {
  section.partners {
    padding: 80px 0px;
  }
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .container > * {
    flex: 1;
  }
  img {
    max-height: 179px;
    margin-left: 48px;
  }
}
</style>
