<template>
  <div id="app">
    <Nav/>
    <div class="flier">
      <a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb8d14376a2da9e500aa8fa2537351c0440e06c61">
        <img src="@/assets/animated-pup1.gif"/>
      </a>
    </div>
    <router-view/>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav,
  },
}
</script>

<style>
body {
  margin: 0;
  background: #262626;
  color: #b62525;
  background: #ffefef;
  font-weight: 500;
}
body[data-theme='dark'],
body[data-theme='dark'] nav,
body[data-theme='dark'] nav ul {
  background: #831d1f;
  color: white;
}
body[data-theme='dark'] h1,
body[data-theme='dark'] h3,
body[data-theme='dark'] nav a {
  color: white;
}
body[data-theme='dark'] .bg-white {
  background: #352d2d;
  /* background: black; */
}
body[data-theme='dark'] .together ul a:hover,
body[data-theme='dark'] .mission ul a:hover {
  color: #831d21;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0 auto;
  padding-top: 52px;
  scroll-behavior: smooth;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.7;
  max-width: 100%;
}
#particles-js canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.bg-white {
  background: white;
}
section {
  padding: 56px 20px 64px;
}
section > * {
  max-width: 627px;
  margin: auto;
}
section p {
  margin: 18px auto;
}
.container {
  margin: 0 auto;
  scroll-behavior: smooth;
}
h1, h3 {
  color: #c82b30;
}
h1 {
  font-size: 48px;
  margin: 16px auto;
  line-height: 1.1;
}
h3 {
  margin: 80px 0 0;
  font-size: 32px;
  line-height: 1.3;
}
p, li {
  font-size: 18px;
}
ul {
  text-align: left;
}
ul.list-style-none {
  list-style-type: none;
  padding: 0;
  text-align: center;
}
ul.list-style-none li {
  margin: 0 10px;
}
li {
  padding-left: 32px;
}
.list-icon-wrapper {
  display: flex;
  justify-content: center;
}
li.list-icon {
  display: flex;
  width: 45px;
  margin: 0 16px;
}
li.list-icon a {
  max-width: 45px;
  max-height: 45px;
  width: 45px;
}
li.list-icon img {
  height: 100%;
  min-height: 100%;
  width: auto;
}
a {
  color: #4900e9;
}
.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 32px;
}
a.button {
  color: white;
  background: orange;
  display: flex;
  padding: 12px;
  border-radius: 12px;
  text-decoration: none;
  align-items: center;
  transition: background 350ms;
}
a.button:hover {
  background: black;
}
a.button img {
  max-height: 40px;
  margin-right: 8px;
}
a.button span {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.container {
  position: relative;
}
.scroll-target {
  position: absolute;
  top: 70%;
  width: 1px;
  height: 1px;
  opacity: 0;
}

/* ANIMATION */
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(50px);
  opacity: 0;
}

/* ANIMATION */
.flier > * {
  animation: fly 50s linear infinite;
  /* pointer-events: none !important; */
	top: 0;
	left: 0;
	transform: translateX(-120%) translateY(-120%) rotateZ(0);
	position: fixed;
	animation-delay: 1s;
	z-index: 999999;
}
.flier img {
  width: 170px !important;
  margin: 0 !important;
}
@media (min-width: 769px) {
  .flier img {
    width: 300px !important;
  }
}

@media (max-width: 768px) {
  .buttons {
    flex-direction: column;
  }
  .buttons a.button {
    margin-top: 16px;
    justify-content: center;
  }
}

@media (min-width: 769px) {
  #app {
    margin-top: 0;
  }
  section {
    padding: 130px 0;
  }
  section p {
    margin: 20px auto;
  }
  h1 {
    margin-top: 0;
  }
  h3 {
    font-size: 32px;
    line-height: 1.3;
  }
  p, li {
    font-size: 20px;
  }
}

@keyframes fly {
	98.001%, 0% {
    display: block;
		transform: translateX(-200%) translateY(100vh) rotateZ(0deg)
	}

	15% {
		transform: translateX(100vw) translateY(-100%) rotateZ(180deg)
	}

	15.001%, 18% {
		transform: translateX(100vw) translateY(-30%) rotateZ(0deg)
	}

	40% {
		transform: translateX(-200%) translateY(3vh) rotateZ(-180deg)
	}

	40.001%, 43% {
		transform: translateX(-200%) translateY(-100%) rotateZ(-180deg)
	}

	65% {
		transform: translateX(100vw) translateY(50vh) rotateZ(0deg)
	}

	65.001%, 68% {
		transform: translateX(20vw) translateY(-200%) rotateZ(180deg)
	}

	95% {
		transform: translateX(10vw) translateY(100vh) rotateZ(0deg)
	}
}
</style>
