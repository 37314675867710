<template>
  <section class="funds-raised">
    <div class="container">

      <div class="scroll-target">
        <intersection-observer
          sentinal-name="sentinal-name"
          @on-intersection-element="onIntersectionSection"
        />
      </div>

      <div class="overlay"/>

      <video autoplay muted loop poster="@/assets/cute-husky-puppy-yawning.png">
        <source src="@/assets/cute-husky-puppy-yawning.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>

      <div class="content">
        <div class="fund-block">
          <transition name="slide-fade">
            <animated-num
              ref="number1"
              :from="0"
              :to="100000"
              :duration="5"
              :delay="100"
              easing="Power1.easeOut"
              v-if="start"
            />
          </transition>

            <h2 v-scroll-reveal>AMOUNT DONATED</h2>

            <p v-scroll-reveal>Total of our (blockchain verified) donations to Binance Lunch For Children.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AnimatedNum from './AnimatedNum.vue'
import IntersectionObserver from './IntersectionObserver.vue'

export default {
  name: 'FundsRaised',
  components: {
    AnimatedNum,
    IntersectionObserver
  },
  data() {
    return {
      start: false,
      startCount: false,
    }
  },
  methods: {
    onIntersectionSection() {
      this.start = true
    },
    onIntersectionContent() {
      this.startCount = true
    }
  }
}
</script>

<style scoped>
section.funds-raised {
  position: relative;
  padding: 0;
}
section.funds-raised > * {
  max-width: initial;
}
.container {
  position: relative;
  display: flex;
  justify-content: center;
}
video {
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 700px;
  object-fit: cover;
}
.content {
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  padding: 20px;
}
h1 {
  font-size: 60px;
  color: white;
}
p {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .funds-raised {
    max-height: 700px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    margin: 0;
    font-size: 20px;
  }
  p {
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  h1 {
    font-size: 80px;
  }
}
</style>
