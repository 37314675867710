<template>
  <div :class="'burger-container ' + (active ? 'active' : '')" @click="$emit('change-active-state');">
    <div class="meat meat-1" />
    <div class="meat meat-2" />
    <div class="meat meat-3" />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>
.burger-container {
  position: fixed;
  width: 38px;
  height: 38px;
  cursor: pointer;
  top: 1rem;
  left: 1.25rem;
  z-index: 6;
  background: transparent;
}
.meat {
  position: absolute;
  width: 28px;
  height: 2px;
  background: #222;
  top: calc(50% - 2px / 2);
  left: calc(50% - 28px / 2);
  transition: all 150ms ease-in;
}
.meat-1 {
  transform: translateY(-10px);
}
.meat-2 {
  width: calc(28px - 6px);
}
.meat-3 {
  transform: translateY(10px);
}
.active .meat-1 {
  transform: rotate(45deg);
}
.active .meat-2 {
  opacity: 0;
}
.active .meat-3 {
  transform: rotate(-45deg);
}

@media (min-width: 769px) {
  .burger-container {
    display: none;
  }
}
</style>
