<template>
  <div class="theme-switch-wrapper">
    <label class="theme-switch" for="checkbox">
        <input type="checkbox" id="checkbox" @change="switchTheme" :checked="isChecked" />
        <div class="slider round"></div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'ToggleTheme',
  data() {
    return {
      isChecked: false
    }
  },
  created() {
    const currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : 'light'

    document.body.setAttribute('data-theme', currentTheme)
    
    if (currentTheme === 'dark') {
      this.isChecked = true
    }
  },
  methods: {
    switchTheme(e) {
      if (e.target.checked) {
        document.body.setAttribute('data-theme', 'dark')
        localStorage.setItem('theme', 'dark')
      } else {
        document.body.setAttribute('data-theme', 'light')
        localStorage.setItem('theme', 'light')
      }
    }
  }
}
</script>

<style scoped>
.theme-switch-wrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;
}
em {
  margin-left: 10px;
  font-size: 1rem;
}
.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

input:checked + .slider {
  background-color: orange;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media (max-width: 768px) {
  .theme-switch-wrapper {
    position: fixed;
    top: 20px;
    right: 1.25rem;
  }
}
</style>