<template>
  <h1>${{ tweenedNumber }}</h1>
</template>

<script>
import {TweenLite} from 'gsap'

// Returns the number of full stop in given string.
const countFullstops = (str) => str.replace(/[^.]/g, '').length

export default {
  name: 'number',
  props: {
    from: {
      type: [Number, String],
      default: 0
    },
    to: {
      type: [Number, String],
      required: true,
      default: 0
    },
    duration: {
      type: Number,
      default: 1 // Duration of animation in seconds
    },
    easing: {
      type: String,
      default: 'Power1.easeOut'
    },
    delay: {
      type: Number,
      default: 0 // Delay the animation in seconds
    },
  },
  data() {
    return { 
      fromProp: this.from
    }
  },
  computed: {
    tweenedNumber () {
      return this.format(this.fromProp)
    }
  },
  methods: {
    format(num) {
      return this.numberWithCommas(parseInt(num))
    },
    numberWithCommas(n) {
      var parts=n.toString().split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    tween (value) {
      const vm = this
      const tLite = TweenLite
        .to(vm.$data, vm.duration, {
          fromProp: value,
        })
      vm.tween.tLite = tLite
    },
    play () {
      this.tween.tLite.play()
    },
    pause () {
      this.tween.tLite.pause()
    },
    restart () {
      this.tween.tLite.restart()
    },
    easeCheck () {
      const vm = this
      if (countFullstops(vm.easing) !== 1) {
        throw new Error('Invalid ease type. (eg. easing="Power1.easeOut")')
      }
      return vm.easing
    }
  },
  watch: {
    to (newValue) {
      this.tween(newValue)
    }
  },
  mounted() {
    this.tween(this.to)
  }
}
</script>